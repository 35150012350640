import { useMemo, useState } from "react";
import { withAppLayout, withAuthentication } from "hocs";
import { Box, Button, Container } from "@material-ui/core";
import ActivityItemBase from "components/Activity/ActivityItemBase";
import ActivityTrending from "components/Activity/ActivityTrending";
import SuggestedFriends from "components/Activity/SuggestedFriends";
import mapItemTypeToDataExtractor from "extractors/home-feed";
import Paginator from "components/Paginator";
import APIEndpoints from "api/APIEndpoints";
import { useTranslation } from "i18n";
import { useSession } from "store";

const mapItemTypeToComponent = {
  0: ActivityItemBase,
  1: ActivityTrending,
  2: SuggestedFriends,
};

const Home = () => {
  const [followingFilter, setFollowingFilter] = useState(false);
  const { data } = useSession();
  const { t } = useTranslation();
  const filters = useMemo(() => ({
    profileId: data.profileId,
    following: !!followingFilter,
  }), [data, followingFilter]);

  return (
    <Container maxWidth="sm">
      <Box mt={3} display="flex" justifyContent="center" mb={2}>
        <Button
          onClick={() => setFollowingFilter(!followingFilter)}
          style={{
            width: 105,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: "50%",
            borderBottomLeftRadius: "50%",
          }}
          variant={followingFilter ? "outlined" : "contained"}
          color="primary"
        >
          {t("FOR_YOU")}
        </Button>
        <Button
          onClick={() => setFollowingFilter(!followingFilter)}
          style={{
            width: 105,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
          }}
          color="primary"
          variant={!followingFilter ? "outlined" : "contained"}
        >
          {t("FOLLOWING")}
        </Button>
      </Box>
      <Paginator
        endpoint={APIEndpoints.FEED.GET}
        ListLoadingComponent={() => <ActivityItemBase.Skeleton />}
        ListEmptyComponent={() => <ActivityItemBase.NotFound />}
        filters={filters}
        renderItem={(item) => {
          const Component = mapItemTypeToComponent[item.type];
          const dataExtractor = mapItemTypeToDataExtractor[item.type];
          if (!dataExtractor || !Component) return null;
          const data = dataExtractor(item.content);
          if (!data) return null;
          return <Component {...data} />;
        }}
      />
    </Container>
  );
};

export default withAppLayout(withAuthentication(Home));
