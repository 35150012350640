import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { profiles } from "api";
import { useAppState, useSession } from "store";
import { useTranslation } from "i18n";
import { cancelPropagation } from "utils";

const FollowButton = ({
  profileId, following, noUnfollow, onChangeFollow, ...rest
}) => {
  const [isFollowing, setFollowing] = useState(following);
  const [loading, setLoading] = useState(false);
  const { data } = useSession();
  const { cache, setCache } = useAppState();
  const { t } = useTranslation();
  useEffect(() => {
    if (typeof cache.follows[profileId] === "boolean") {
      setFollowing(cache.folllows[profileId]);
    }
  }, [cache.follows, profileId]);

  const shouldShowFollowButton = (
    data && !(noUnfollow && isFollowing)
    && data?.profileId !== profileId
  );

  const follow = async (e) => {
    cancelPropagation(e);
    setLoading(true);
    const { error } = await profiles.follow(profileId);
    setLoading(false);
    if (!error) {
      setFollowing(true);
      setCache({ ...cache, follows: { ...cache.follows, [profileId]: true } });
      if (onChangeFollow) onChangeFollow(1);
    }
  };

  const unfollow = async (e) => {
    cancelPropagation(e);
    setLoading(true);
    const { error } = await profiles.unFollow(profileId);
    setLoading(false);
    if (!error) {
      setFollowing(false);
      setCache({ ...cache, follows: { ...cache.follows, [profileId]: false } });
      if (onChangeFollow) onChangeFollow(-1);
    }
  };
  if (!shouldShowFollowButton) return null;
  return (
    <Button
      {...rest}
      disabled={loading}
      onClick={isFollowing ? unfollow : follow}
      variant={isFollowing ? "outlined" : "contained"}
      color="primary"
      size="small"
    >
      {isFollowing ? t("FOLLOWING") : t("FOLLOW")}
    </Button>
  );
};

export default FollowButton;
