import {
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import HorizontalScroll from "components/HorizontalScroll";
import ProfileItem from "components/Search/ProfileItem";
import { useTranslation } from "i18n";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    minHeight: 200,
  },
}));

const ActivityTrending = ({ users }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" className="boldy">
        {t("USERS_SUGGESTED")}
      </Typography>
      <HorizontalScroll>
        {
          users.map((item) => (
            <ProfileItem {...item} />
          ))
        }
      </HorizontalScroll>
    </Paper>
  );
};

export default ActivityTrending;
