import {
  Box,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Star } from "@material-ui/icons";
import HorizontalScroll from "components/HorizontalScroll";
import ItemCard from "components/ItemCard";
import { useTranslation } from "i18n";

const useStyles = makeStyles((theme) => ({
  "@keyframes blinker": {
    from: {
      boxShadow: `0 0 20px ${theme.palette.primary.light}`,
    },
    to: {
      boxShadow: `0 0 45px ${theme.palette.primary.light}`,
    },
  },
  paper: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    minHeight: 200,
    animationName: "$blinker",
    animationDuration: "2s",
    animationDirection: "alternate",
    animationTimingFunction: "easing",
    animationIterationCount: "infinite",
  },
}));

const ActivityTrending = ({ platform, items }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" className="boldy">
        {`${t("TRENDING_IN")} ${platform} ${t("TODAY")}`}
      </Typography>
      <HorizontalScroll>
        {
          items.map((item) => (
            <ItemCard
              {...item}
              subtitle={(
                <Box display="flex" alignItems="center">
                  <Star color="primary" />
                  <Typography variant="body2" className="bold">
                    {item.rating}
                  </Typography>
                </Box>
              )}
            />
          ))
        }
      </HorizontalScroll>
    </Paper>
  );
};

export default ActivityTrending;
