import { capitalize } from "lodash";
import { parseJsonOrNull } from "utils";
import moment from "moment";

export default {
  0: (data) => {
    if (!data.modifier.props) return null;
    if (!["COMMENT", "VALUATION", "REACT_COMMENT"].includes(data.operation.name)) return null;
    return ({
      date: data.date,
      itemId: data.modifier.props.itemId,
      commentId: data.modifier.props.itemCommentId,
      liked: data.modifier.props.isLiked,
      totalLikes: data.modifier.props.totalReaction,
      creator: data.creator,
      operation: data.operation.name,
      content: {
        text: data.modifier.props.comment,
        valuation: data.object.props.itemCreatorValuation,
        targetItem: {
          id: data.object.id,
          name: data.object.name,
          chips: [
            data.object.props.itemGenre,
            moment(data.object.props.itemReleaseDate).year(),
          ],
          asset: data.object.asset,
          subItems: (parseJsonOrNull(data.object.props.itemCredit) || []).map((item) => ({
            id: item.CelebrityId,
            name: item.Name,
            asset: item.ItemAsset,
          })),
        },
      },
    });
  },
  1: (data) => ({
    platform: capitalize(data.sourceName.replace("_", " ")),
    items: data.elements.map((i) => ({
      itemId: i.itemId,
      title: i.itemName,
      assetUrl: i.itemAsset,
      rating: i.itemValuation,
    })),
  }),
  2: (data) => ({
    users: data.elements.map((e) => ({
      ...e,
      following: e.relationshipStatus !== "UNFOLLOWED",
      profileAffinity: e.affinity,
    })),
  }),
};
