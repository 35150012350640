import { Button, makeStyles, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import FollowButton from "components/FollowButton";
import ResourceProfile from "components/Resources/ResourceProfile";
import { useTranslation } from "i18n";
import { useRouter } from "next/router";
import { chopString } from "utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 170,
    width: 135,
  },
  userBox: {
    display: "inherit",
    flexDirection: "inherit",
    alignItems: "inherit",
    height: "100%",
    width: "100%",
    textAlign: "center",
  },
  usernameContainer: {
    marginTop: theme.spacing(2),
    marginLeft: 0,
  },
}));

const ProfileItem = ({
  avatarUrl,
  username,
  roleId,
  level,
  profileAffinity,
  following,
  profileId,
}) => {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation();

  const navigateToItem = () => {
    if (profileId) router.push(`/profile?id=${profileId}`);
  };

  return (
    <Button onClick={navigateToItem}>
      <Paper className={classes.paper}>
        <ResourceProfile
          usernameContainerClassName={classes.usernameContainer}
          className={classes.userBox}
          level={level}
          roleId={roleId}
          avatarUrl={avatarUrl}
          username={chopString(username, 12)}
        />
        <Typography variant="caption" color="textSecondary">
          {
            profileAffinity ? (
              `${profileAffinity}% ${t("OF_AFFINITY")}`
            )
              : "-"
          }
        </Typography>
        <FollowButton
          following={following}
          profileId={profileId}
        />
      </Paper>
    </Button>
  );
};

ProfileItem.Skeleton = () => {
  const classes = useStyles();
  return (
    <Button>
      <Paper className={classes.paper}>
        <Skeleton variant="circle" className={classes.avatar} />
        <Skeleton height={10} width="65%" />
        <Skeleton height={40} width="65%" />
      </Paper>
    </Button>
  );
};

ProfileItem.NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Button>
      <Paper className={classes.paper}>
        <Skeleton variant="circle" className={classes.avatar} />
        <Typography className="boldy" color="textSecondary">
          {t("RESULTS_NOT_FOUND")}
        </Typography>
      </Paper>
    </Button>
  );
};

export default ProfileItem;
